module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://staging.thecoalitionstudio.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"stylesProvider":{"injectFirst":true},"disableAutoprefixing":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://cms.live.gearsofwar.com/coalition-studio/graphql","verbose":true,"debug":{"graphql":{"showQueryVarsOnError":true,"writeQueriesToDisk":true,"showQueryOnError":false,"copyQueryOnError":false,"panicOnError":false,"onlyReportCriticalErrors":true,"copyNodeSourcingQueryAndExit":false,"printIntrospectionDiff":false},"preview":false,"timeBuildSteps":false,"disableCompatibilityCheck":false,"throwRefetchErrors":false},"develop":{"hardCacheMediaFiles":true,"nodeUpdateInterval":5000,"hardCacheData":false},"production":{"allow404Images":true,"hardCacheMediaFiles":false},"schema":{"typePrefix":"WPSite","requestConcurrency":10,"timeout":60000,"queryDepth":15,"circularQueryLimit":5,"perPage":100,"previewRequestConcurrency":5},"html":{"imageMaxWidth":3840,"useGatsbyImage":false,"fallbackImageMaxWidth":100,"imageQuality":90,"createStaticFiles":true,"generateWebpImages":false},"type":{"User":{"exclude":true},"UserRole":{"exclude":true},"Comment":{"exclude":true},"PostFormat":{"exclude":true},"Menu":{"excludeFieldNames":["locations"]},"RootQuery":"{ excludeFieldNames: ['viewer', 'node', 'schemaMd5'], },"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"The Coalition Studio","short_name":"The Coalition Studio","start_url":"/","display":"standalone","icon":"static/favicon.png","background_color":"#F6F7F9","theme_color":"#F6F7F9","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"c693adcec79baeb74c22449a3e77bf68"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
